import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'

const ORDER = gql`
    query {
      cartPrices {
        subtotalNow
        subtotalRecurring
        totalNow
        totalRecurring
        vatNow
        vatRecurring
      }
    }
  `

const TRIAL_ON_HOLD_ORDER_PRICES = gql`
  query {orderPrices {
    totalNow
    subtotalNow
    vatNow
    totalRecurring
    subtotalRecurring
    vatRecurring
    licenseCount
  }}
`

export default function Order({ license, privateDetails, setPrivateDetails, companyDetails, setCompanyDetails, setStep, setDetailsStep, customer }) {

  const [orderStatus, setOrderStatus] = useState('resolving')
  const [trialOnHoldStatus, setTrialOnHoldStatus] = useState('resolving')
  const [checked, setChecked] = useState(false)
  const { data: order, refetch: refetchOrder } = useQuery(ORDER, {
    onCompleted: (res) => {
      // console.log(res);
      setOrderStatus('resolved')
      // setOrder(orderData)
    },
    onError: (err) => {
      console.log(err);
      setOrderStatus('resolved')
    }
  })
  const { data: trialOnHoldOrder, refetch: refetchTrialOnHoldOrder } = useQuery(TRIAL_ON_HOLD_ORDER_PRICES, {
    onCompleted: (res) => {
      // console.log(res);
      setTrialOnHoldStatus('resolved')
      // setOrder(orderData)
    },
    onError: (err) => {
      console.log(err);
      setTrialOnHoldStatus('resolved')
    }
  })

  useEffect(() => {
    setOrderStatus('resolving')
    refetchOrder().then(() => { setOrderStatus('resolved')}).catch(() => setOrderStatus('resolved'));
  }, [license, order])

  useEffect(() => {
    setTrialOnHoldStatus('resolving')
    refetchTrialOnHoldOrder().then(() => { setTrialOnHoldStatus('resolved')}).catch(() => setTrialOnHoldStatus('resolved'));
  }, [license, trialOnHoldOrder])

  useEffect(() => {
    if (license.type === "private") {
      setCompanyDetails(details => ({
        ...details,
        contactperson: `${privateDetails.firstname} ${privateDetails.lastname}`,
        country: privateDetails.country,
        address: privateDetails.address,
        postcode: privateDetails.postcode,
        city: privateDetails.city
      }))
    }
    else {
      setPrivateDetails(details => ({
        ...details,
        country: companyDetails.country,
        address: companyDetails.address,
        postcode: companyDetails.postcode,
        city: companyDetails.city
      }))
    }
  }, [])

  return (
    <div className="complete-order">
      <form onSubmit={() => {
        setStep(4)
      }}>
        <div className="order-container border">
          <h4>Min order</h4>
          <ul className="order-details border">
            <li>
              <p className="title">Privat/Företag</p>
              <div className="detail">
                {/* <input className="input-edit" name="typeEdit" id="typeEdit" defaultValue={license.type === "privateDetails" ? "Privat" : "Företag"} disabled={typeEdit === false}/> */}
                <span>{license.type === "private" ? "Privat" : "Företag"}</span>
                {/* <span onClick={() => {
                  license.type === "private" 
                    ? setLicense(license => ({...license, type: "company"}))
                    : setLicense(license => ({ ...license, type: "private" }))
                }} className="custom c-pointer hover-underline">Ändra</span> */}
                <button onClick={() => { setDetailsStep(1); setStep(1); }} className="custom hover-underline">Ändra</button>
              </div>
            </li>

            {/* Licenser */}
            <li>
              <p className="title">Antal</p>
              <div className="detail">
                <p>Pausit {license.quantity} licenser </p>
                <button onClick={() => { setDetailsStep(1); setStep(1); }} className="custom hover-underline">Ändra</button>
              </div>
            </li>

            {/* Företagsnamn */}
            {license.type === "company" &&
              <li>
                <p className="title">Företagsnamn</p>
                <div className="detail">
                  <p>{companyDetails.company}</p>
                  <button onClick={() => { setStep(2); setDetailsStep(1); }} className="custom hover-underline">
                    Ändra
                  </button>
                </div>
              </li>
            }

            {/* Privat Namn / Kontaktperson */}
            <li>
              <p className="title">
                {license.type === "private" ? "Namn" : "Kontaktperson"}
              </p>
              <div className="detail">
                {/* Ändra Privatnamn */}
                <p>
                  {license.type === "private"
                    ? `${privateDetails.firstname} ${privateDetails.lastname}`
                    : `${companyDetails.contactFirstname} ${companyDetails.contactLastname}`
                  }
                </p>
                <button onClick={() => {
                  setStep(2)
                  setDetailsStep(1)
                }} className="custom hover-underline">Ändra</button>
              </div>
            </li>

            {/* Address */}
            <li>
              <p className="title">Adress</p>
              <div className="detail">
                <div>
                  <p>{privateDetails.address}</p>
                  <p>{privateDetails.postcode} {privateDetails.city}</p>
                </div>
                <button onClick={() => {
                  setStep(2);
                  if (license.type === "private") {
                    setDetailsStep(2)
                  }
                  else {
                    setDetailsStep(1)
                  }
                }} className="custom hover-underline">Ändra</button>
              </div>
            </li>
          </ul>

          <ul className={`order-total`}>
            <li>
              <div className="detail">
                <span>Innan moms</span>
                <span className={`${orderStatus === 'resolving' && 'loading price-loading animate-2'}`}>
                  {customer?.subscription.subscriptionType !== "TRIAL_ON_HOLD" && orderStatus !== 'resolving' && order?.cartPrices.subtotalRecurring}
                  {customer?.subscription.subscriptionType === "TRIAL_ON_HOLD" && trialOnHoldStatus !== 'resolving' && trialOnHoldOrder?.orderPrices.subtotalRecurring}
                </span>
              </div>
            </li>
            <li>
              <div className="detail">
                <span>Moms</span>
                <span className={`${orderStatus === 'resolving' && 'loading price-loading animate-2'}`}>
                  {customer?.subscription.subscriptionType !== "TRIAL_ON_HOLD" && orderStatus !== 'resolving' && order?.cartPrices.vatRecurring}
                  {customer?.subscription.subscriptionType === "TRIAL_ON_HOLD" && trialOnHoldStatus !== 'resolving' && trialOnHoldOrder?.orderPrices.vatRecurring}
                </span>
              </div>
            </li>
            <li>
              <div className="detail">
                <h4>Totalt</h4>
                <span className={`total ${orderStatus === 'resolving' && 'loading price-loading animate-2'}`}>
                  {customer?.subscription.subscriptionType !== "TRIAL_ON_HOLD" && order && orderStatus !== 'resolving' &&
                    <>
                      <h4>{order?.cartPrices.totalRecurring} kr</h4>
                      {order?.cartPrices.totalRecurring && '/per år.'}
                    </>
                  }
                  {customer?.subscription.subscriptionType === "TRIAL_ON_HOLD" && order && trialOnHoldStatus !== 'resolving' &&
                    <>
                      <h4>{trialOnHoldOrder?.orderPrices.totalNow} kr</h4>
                    </>
                  }
                </span>
              </div>
            </li>
          </ul>
          {
            customer?.subscription.subscriptionType !== "EXPIRED" && customer?.subscription.subscriptionType !== "TRIAL_ON_HOLD" &&
            <p className="info">Första debitering sker {`${dayjs(new Date()).add(21, 'day').get('date').toString()}/${(dayjs(new Date()).add(21, 'day').get('month') + 1).toString()}-${dayjs(new Date()).add(21, 'day').get('year').toString().substr(-2)}`}</p>
          }
        </div>

        <label className="agreement checkbox-container">
          <p>Godkänn vår <a href="/integritetspolicy.pdf" target="_blank">Integritetspolicy</a> och våra <a href="/allmanna_licensvillkor.pdf" target="_blank">Allmänna licensvillkor</a></p>
          <input required type="checkbox" name="agreement" onChange={(e) => setChecked(e.target.checked)}/>
          <span className="checkmark"></span>
        </label>
        {/* <label htmlFor="agreement">Godkänn köpevillkoren</label> */}

        <button disabled={!checked} type="submit" className={`arrow`}>Gå vidare</button>
      </form>
    </div>
  )
}
