import React, { useState } from 'react'
import { Link } from "gatsby"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutSteps from './CheckoutSteps'
import { useAppState } from '../context'
import Line from '../../images/line.svg'
import CookieBar from "../../components/CookieBar";

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_KEY,
  { locale: 'sv' }
)

export default function CheckoutPage() {

  const { cartStatus } = useAppState();
  const { customerData } = useAppState()
  const [step, setStep] = useState(0)
  const [isCreditCard, setIsCreditCard] = useState(true)
  const [detailsStep, setDetailsStep] = useState(1)

  const [authError, setAuthError] = useState(false)

  return (
    <div className="order">
      <Elements stripe={stripePromise}>
        {step > 0 &&
          <ul className="steps">
            <li onClick={() => { setStep(1); localStorage.removeItem('woo-session') }} className={`step`}>Välj licens</li>
            <li onClick={() => step > 2 && setStep(2)} className={`step ${step < 2 && 'disabled'}`}>
              <object data={Line} type="image/svg+xml">
                <img src={Line} alt="line" />
              </object>
              Kontouppgifter
            </li>
            <li onClick={() => step > 3 && setStep(3)} className={`step ${step < 3 && 'disabled'}`}>
              <object data={Line} type="image/svg+xml">
                <img src={Line} alt="line" />
              </object>
              Sammanställning
            </li>
            <li className={`step ${step < 4 && 'disabled'}`}>
              <object data={Line} type="image/svg+xml">
                <img src={Line} alt="line" />
              </object>
              Betalning
            </li>
          </ul>
        }
        {/* Checkout steps */}
        <div>
          <CheckoutSteps step={step} setStep={(value) => setStep(value)} isCreditCard={isCreditCard} setIsCreditCard={(value) => setIsCreditCard(value)} detailsStep={detailsStep} setDetailsStep={(value) => setDetailsStep(value)} authError={authError} setAuthError={(v) => setAuthError(v)} />
          { cartStatus.status === 'cart-error' && <span className="cart-error">Något gick fel i val av licens. Försök igen.</span> }
        </div>

        {/* Footer */}
        {!authError ?
          <div className="footer">
            {step === 0
              ? <Link to={customerData && customerData?.subscription.subscriptionType !== "NONE" ? "/user" : "/shop"}><span className="step-back">Tillbaka</span></Link>
              : <a role="button" href="/shop/checkout" onClick={(e) => {
                e.preventDefault();
                if (step === 1) {
                  setStep(step - 1)
                }
                else if (step === 2 && detailsStep === 2) {
                  setDetailsStep(detailsStep - 1)
                }
                else if (step === 2 && detailsStep === 1) {
                  localStorage.removeItem('woo-session')
                  setStep(step - 1)
                }
                else if (step === 4 && !isCreditCard) {
                  setIsCreditCard(true)
                }
                else {
                  setStep(step - 1)
                }
              }} className="step-back">
                Tillbaka
              </a>
            }
          </div>
          : <div></div>
        }
      </Elements>
      {/* <CookieBar /> */}
    </div>
  )
}

