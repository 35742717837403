import { Link, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { useAppState } from '../context';
import ArrowRight from '../../images/icons/chevron-right.svg'
import download from '../../images/icons/download.svg'
import { useCustomerQuery } from '../../hooks/queries/useCustomerQuery'
import Loader from 'react-spinners/BeatLoader'
export default function OrderReceived({ trial }) {

  const { customerData } = useAppState();
  const { getCustomer, loading: customerLoading } = useCustomerQuery();
  useEffect(() => {
    getCustomer();
  }, [])

  const copyKey = (e) => {
    const input = document.querySelector(".copy-input")
    input.select()
    document.execCommand('copy');
    input.focus();
    e.target.textContent = 'Kopierad!'
  }

  return (
    <div className={`order-received ${trial && 'trial'}`}>
      <h2>{trial ? 'Tack!' : 'Tack för ditt köp!'}</h2>
      {trial && <h2>Nu kan du ladda ner programmet</h2>}
      {!trial &&
        <>
          <p>Nu kan du välja att ladda ner programmet direkt eller gå till mina sidor. Licensnyckel och
            nedladdningslänkar finns även inne på mina sidor.</p>
        </>
      }
      <div className="license-key">
        <span>Licensnyckel:</span>

        <div className={`key ${customerLoading ? 'loading' : ''}`}>
          {customerLoading
            ? <Loader color={"#ffffff83"} />
            : <>
              <input readOnly className="copy-input" type="text" value={customerData?.subscription?.license.key} />
              <button className="sm" onClick={(e) => copyKey(e)}>Kopiera</button>
            </>
          }
        </div>
      </div>
      <div className="options">
        <button onClick={() => { customerData?.downloads.length > 0 && (customerData?.subscription?.subscriptionType !== "NONE" || customerData?.subscription?.subscriptionType !== "EXPIRED") && window.location.replace(`${customerData.downloads[0].url}`) }} className="dl">
          Ladda ner Pausit för Windows
          <img src={download} alt="dl-windows" />
        </button>
        <button onClick={() => { customerData?.downloads.length > 0 && (customerData?.subscription?.subscriptionType !== "NONE" || customerData?.subscription?.subscriptionType !== "EXPIRED") && window.location.replace(`${customerData.downloads[1].url}`) }} className="dl">
          Ladda ner Pausit för Mac
          <img src={download} alt="dl-mac" />
        </button>
      </div>

      {trial && <>
        <p>På mina sidor kan du förlänga din prenumeration av Pausit.</p>
        <p>Om du inte väljer att göra det avslutas din testperiod helt automatiskt utan kostnad.</p>
      </>}

      {trial || customerData?.subscription?.subscriptionType !== "TRIAL" ?
        <button onClick={() => navigate("/user")} className="custom">
          Gå till mina sidor
          <img src={ArrowRight} alt="arrow" />
        </button>
        : <button onClick={() => window.location.replace("/user")} className="custom">
          Gå till mina sidor
          <img src={ArrowRight} alt="arrow" />
        </button>
      }
      <p>Välkommen att ringa om du har några frågor 019-187000</p>
      {trial && <Link className="hover-underline arrow" to="/shop/checkout">Förresten, jag vill köpa ändå, det är ju ändå gratis i 21 dagar!</Link>}
    </div>
  )
}
