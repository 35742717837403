import React, { useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics'
import { useStripeSource } from "../../hooks/useStripeSource"
import ClipLoader from 'react-spinners/ClipLoader'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe } from '@stripe/react-stripe-js'
import Creditcard from '../../images/creditcard.svg'
import { navigate } from 'gatsby'
import { gql, useMutation } from '@apollo/client'

export default function CheckoutForm({ license, authError, privateDetails, companyDetails, customerData, setAuthError, setIsCreditCard }) {

  
  const [checkoutError, setCheckoutError] = useState(false)
  const [message, setMessage] = useState('')
  const [resolveStatus, setResolveStatus] = useState('')
  const { viewer } = useAuth();
  const [setupIntent, setSetupIntent] = useState("")
  const [order, setOrder] = useState()
  const [source, setSource] = useState()

  const { getCid, trackingId } = useGoogleAnalytics();
  const { handleStripe } = useStripeSource()

  const stripe = useStripe()

  // Payment
  const [paymentDetails, setPaymentDetails] = useState({
    name: ""
  })

  // Checkout körs
  const kbHandle = async (event) => {
    event.preventDefault();
    setResolveStatus('resolving')
    setCheckoutError(false)
    setMessage('Genomför ditt köp...')
    try {
      const source = await handleStripe(
        paymentDetails,
        CardNumberElement,
        setResolveStatus
      )
      const clientId = await getCid()
      setSource(source.id)
      switch (license.type) {
        case "private":
          await kbCheckout({
            variables: {
              input: {
                clientMutationId: "12345",
                billing: {
                  firstName: privateDetails.firstname,
                  lastName: privateDetails.lastname,
                  address1: privateDetails.address,
                  city: privateDetails.city,
                  postcode: privateDetails.postcode,
                  email: viewer.email,
                },
                stripeSource: source.id,
                gaCID: clientId,
                gaTID: trackingId
              },
            },
          })
          break;
        case "company":
          await kbCheckout({
            variables: {
              input: {
                clientMutationId: "12345",
                billing: {
                  company: companyDetails.company,
                  firstName: companyDetails.contactFirstname,
                  lastName: companyDetails.contactLastname,
                  address1: companyDetails.address,
                  postcode: companyDetails.postcode,
                  city: companyDetails.city,
                },
                stripeSource: source.id,
                gaCID: clientId,
                gaTID: trackingId,
              },
            },
          })
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error)
    }
  }


  const [kbCheckout] = useMutation(KB_CHECKOUT, {
    onCompleted({ kbCheckout }) {
      if(kbCheckout.result !== "fail") {
        handleSuccessfulCheckout({ order: kbCheckout.order, result: kbCheckout.result, setupIntentSecret: kbCheckout.setupIntentSecret })
      } else {
        setCheckoutError(true);
        setResolveStatus('')
      }
    },
    onError(error) {
      setResolveStatus('')
      console.log(error);
      setCheckoutError(true)
    }
  })


  // När Checkout går igenom
  const handleSuccessfulCheckout = ({ order, setupIntentSecret }) => {
    if (setupIntentSecret === "") {
      setResolveStatus('')
      localStorage.removeItem('woo-session')
      navigate('/shop/checkout/order-received', { state: order })
    }
    // Om autentisering krävs
    else {
      setMessage('Autentisering pågår')
      setSetupIntent(setupIntentSecret);
      setOrder(order)
      stripe.confirmCardSetup(setupIntentSecret).then((result) => {
        if (result.error) {
          console.log(result.error);
          setMessage('Autentisering misslyckades.')
          setAuthError(true);
          setResolveStatus('')
          setMessage('')
        } else {
          setMessage('Autentisering genomförd. Din order slutförs...')
          setAuthError(false)
          setResolveStatus('')
          setSetupIntent('')
          localStorage.removeItem('woo-session')
          navigate('/shop/checkout/order-received', { state: order })
        }
      })
    }
  }

  return (
    <div className="payment">
      <form onSubmit={(e) => {
        if (!authError) {
          kbHandle(e)
        } else {
          e.preventDefault();
          setResolveStatus('resolving')
          setCheckoutError(false)
          setMessage('Autentisering pågår')
          // Försök igen - Autentisering
          stripe.confirmCardSetup(setupIntent, {
            payment_method: source
          }).then((result) => {
            if (result.error) {
                console.log(result.error);
                setMessage('Autentisering misslyckades.')
                setAuthError(true);
                setResolveStatus('')
                setMessage('')
            } else {
                setMessage('Autentisering genomförd. Din order slutförs...')
                setAuthError(false)
                setResolveStatus('')
                setSetupIntent('')
                localStorage.removeItem('woo-session')
                navigate('/shop/checkout/order-received', { state: order })
            }
          }
          )
        }
      }}>

      {!authError &&
        <>
          <div>
              <img src={Creditcard} alt="creditcard" />
              <h2>Betalning</h2>
          </div>
          <div className="form-row">
              <label htmlFor="name">Namn på kort</label>
              <input required minLength={2} onChange={(e) => setPaymentDetails(details => ({ ...details, name: e.target.value }))} type="text" name="name" defaultValue={paymentDetails.name} placeholder="Namn" />
          </div>
          <div className="form-row">
              <label htmlFor="">Kortnummer</label>
              <CardNumberElement />
          </div>
          <div className="form-row d-flex date">
              <div>
              <label>Datum</label>
              <CardExpiryElement />
              </div>
              <div>
              <label>CVC</label>
              <CardCvcElement />
              </div>
          </div>
        </>
      }
      <div className="messages auth-error">
        {authError && <div className="checkout-error">
          <h2>Autentisering misslyckades</h2>
          <div>
              {customerData?.subscription.subscriptionType === "NONE"
              ? <strong>Försök igen eller gå vidare för att påbörja din provperiod.</strong>
              : <strong>Försök igen eller avbryt ditt köp.</strong>
              }
          </div>
          </div>
        }
      </div>

      <div className="d-flex" style={{ justifyContent: 'center' }}>
        <button type="submit" disabled={resolveStatus === 'resolving'}>
          {!authError ? 'Genomför köp' : 'Försök igen'}
        </button>
      </div>
      <div className={`messages ${authError && 'auth-error'}`}>
        {resolveStatus === 'resolving' &&
          <div className="spinner">
              <div><small>{message}</small></div>
              <ClipLoader size={22} color={"#ffffffbb"} />
          </div>
        }
        {checkoutError && <div className="checkout-error">Köpet kunde inte genomföras. Försök igen eller prova ett annat kort.</div>}

      </div>
      {!authError
        ? 
          <span onClick={() => setIsCreditCard(false)}>Jag har inte möjlighet att betala med kort</span>
        : 
          <span onClick={(e) => {
            e.preventDefault();
            if (customerData?.subscription.subscriptionType === "NONE") {
              navigate('/shop/checkout/order-received', { state: order })
            }
            else {
              window.location.replace("/user")
            }
          }}>
        {customerData?.subscription.subscriptionType === "NONE" ? 'Jag vill gå vidare ›' : 'Avbryt köp ›'}</span>}
      </form>
    </div>
  )
}


const KB_CHECKOUT = gql`
  mutation KbCheckout($input: KbCheckoutInput!) {
    kbCheckout(input: $input) {
      order {
        databaseId
        orderNumber
        total
        lineItems {
          nodes {
            product {
              node {
                name
                databaseId
              }
            }
          }
        }
      }
      result
      setupIntentSecret
    }
  }
`