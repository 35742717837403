import React from 'react'
import { useAppState } from '../context'
import { gql, useMutation } from '@apollo/client';
import { useState, useEffect, useRef } from 'react';
import Valid from '../../images/check.svg'
import ClipLoader from 'react-spinners/ClipLoader'

const UPDATE_VAT = gql`
  mutation($input: UpdateVatInput!) {
    updateVat(input: $input) {
      mutationStatus
    }
  }
`
const UPDATE_ORG = gql`
  mutation($input: UpdateOrgNumberInput!) {
    updateOrgNumber(input: $input) {
      mutationStatus
    }
  }
`
let timeout;

export default function CompanyDetails({ companyDetails, setCompanyDetails, detailsStep, setDetailsStep, setStep, country }) {

  const { cartStatus } = useAppState();
  const [companyCheckStatus, setCompanyCheckStatus] = useState(false)

  const [updateOrg, { loading: orgLoading }] = useMutation(UPDATE_ORG, {
    onCompleted: (res) => {
      console.log(res);
      setCompanyCheckStatus(true)
    },
    onError: err => {
      console.log(err.message);
      setCompanyCheckStatus(false)
    }
  })
  const [updateVat, { loading: vatLoading }] = useMutation(UPDATE_VAT, {
    onCompleted: (res) => {
      console.log(res.updateVat);
      console.log(res.updateVat.mutationStatus);
      setCompanyCheckStatus(true)
    },
    onError: (err) => {
      console.log(err.message);
      setCompanyCheckStatus(false)

    }
  })
  const vatInput = useRef()
  const orgInput = useRef()

  const submitDetails = (e) => {
    e.preventDefault();
    setStep(3)
  }

  useEffect(() => {
    if (companyDetails.vat.length > 4) {
      setCompanyCheckStatus('resolving')
      updateVat({
        variables: {
          input: {
            vat: companyDetails.vat.replace(/\s/g, '')
          }
        }
      })
    }
    if (companyDetails.org.length === 11) {
      setCompanyCheckStatus('resolving')
      updateOrg({ variables: { input: { orgNumber: companyDetails.org.replace(/-/g, "") } } })
    }
  }, [])

  const handleCompanyCheck = (e) => {
    e.preventDefault();
    if (country === 'SE') {
      updateOrg({ variables: { input: { orgNumber: companyDetails.org.replace(/-/g, "") } } })
    } else {
      if (companyDetails.vat.length < 4 || companyDetails.country === "") return;
      // setCompanyCheckStatus('resolving')
      updateVat({
        variables: {
          input: {
            vat: companyDetails.vat.replace(/\s/g, '')
          }
        }
      })
    }
  }

  // Lägger till checkbox
  const handleInputValidity = (e) => {
    e.preventDefault();
    switch (e.target.validity.valid) {
      case true:
        e.target.nextSibling?.classList.add('d-block')
        break;
      case false:
        e.target.nextSibling?.classList.remove('d-block')
      default:
        break;
    }
  }

  // Lägger till "-" i organisationsnr
  useEffect(() => {
    companyDetails?.org.length < 11 && companyDetails?.org.length > 0 && setCompanyCheckStatus(false)
    if (companyDetails?.org.length === 10 || companyDetails?.org.length === 11) {
      let hasDash = companyDetails?.org.includes("-")
      if (!hasDash) {
        let orgInput = [...companyDetails?.org];
        orgInput.splice(6, 0, "-");
        setCompanyDetails(details => ({ ...details, org: orgInput.join('') }))
      }
    }
  }, [companyDetails?.org])

  return (
    <div className="details company-details">
      {detailsStep === 1 &&
        <form onSubmit={(e) => {
          e.preventDefault();
          if (country === "SE") {
            (companyCheckStatus === true || orgInput?.current?.value?.length === 0) && !orgLoading && setDetailsStep(2)
          }
          else {
            companyCheckStatus === true && !vatLoading && setDetailsStep(2)
          }
        }}>
          <h2>Företagets uppgifter</h2>
          <div className="form-row">
            <label htmlFor="company">Företagsnamn</label>
            <input onBlur={(e) => handleInputValidity(e)}
              onChange={(e) => { setCompanyDetails(details => ({ ...details, company: e.target.value })); handleInputValidity(e) }} type="text" name="company" defaultValue={companyDetails.company}
              required minLength={2} />
            <img className="valid" src={Valid} alt="check" />
          </div>
          <div className="form-row contact">
            <label htmlFor="contactperson">Kontaktperson</label>
            <div className="d-flex">
              <div className="row">
                <input onBlur={(e) => handleInputValidity(e)}
                  onChange={(e) => { setCompanyDetails(details => ({ ...details, contactFirstname: e.target.value })); handleInputValidity(e) }} type="text" name="contactFirstname" defaultValue={companyDetails.contactFirstname}
                  required minLength={2}
                  placeholder="Förnamn" />
                <img className="valid" src={Valid} alt="check" />
              </div>
              <div className="row">
                <input onBlur={(e) => handleInputValidity(e)}
                  onChange={(e) => { setCompanyDetails(details => ({ ...details, contactLastname: e.target.value })); handleInputValidity(e) }} type="text" name="contactLastname" defaultValue={companyDetails.contactLastname}
                  required minLength={2}
                  placeholder="Efternamn" />
                <img className="valid" src={Valid} alt="check" />
              </div>
            </div>
          </div>

          <div className={`form-row vat ${country === "SE" ? 'se' : ''}`}>
            <label htmlFor="vat">{country === "SE" ? 'Organisationsnummer' : 'VAT-nummer'}</label>
            {country === "SE"
              // Orgnr
              ? <input type="tel"
                onKeyDown={(e) => {
                  if (e.key !== "Backspace" && companyDetails?.org.length === 6) {
                    let hasDash = companyDetails?.org.includes("-")
                    if (!hasDash) {
                      let orgInput = [...companyDetails?.org];
                      orgInput.splice(6, 0, "-");
                      setCompanyDetails(details => ({ ...details, org: orgInput.join('') }))
                    }
                  }
                }}
                onKeyUp={(e) => {
                  if (companyDetails.org.length === 11) {
                    setCompanyCheckStatus('resolving')
                    clearTimeout(timeout)
                    timeout = setTimeout(() => {
                      handleCompanyCheck(e)
                    }, 1000);
                  }
                }}
                onChange={(e) => {
                  setCompanyDetails(details => ({ ...details, org: e.target.value }));
                }}
                maxLength={11} name="org" value={companyDetails.org} ref={orgInput}
              />
              // Vat
              : <input
                // onBlur={(e) => !companyCheckStatus && setTimeout(() => handleCompanyCheck(e), 1000)}
                onKeyUp={(e) => {
                  companyDetails.vat.length > 4 && setCompanyCheckStatus('resolving')
                  clearTimeout(timeout)
                  timeout = setTimeout(() => {
                    handleCompanyCheck(e)
                  }, 1000);
                }} onChange={(e) => { setCompanyDetails(details => ({ ...details, vat: e.target.value })); }}
                type="text" maxLength={15} minLength={4} name="vat" defaultValue={companyDetails.vat} ref={vatInput} required
              />
            }
            {companyCheckStatus === true && <img src={Valid} alt="check" />}
            {(!companyCheckStatus || companyCheckStatus === 'resolving') &&
              <>
                {country === "SE"
                  ? <>{companyDetails.org.length > 0 && <small className="text-start error">Ogiltigt Org-nr</small>}</>
                  : <>{companyDetails.vat.length >= 4 && <small className="text-start error">Ogiltigt VAT-nummer</small>}</>
                }
              </>}
            {companyCheckStatus === 'resolving' && <div className={`vat-spinner ${country === "SE" ? 'orgnr' : ''}`}><ClipLoader size={22} color={"#ffffffbb"} /></div>}
          </div>

          {cartStatus.status !== 'cart-error'
            ? <>
              {/* {(!companyCheckStatus || companyCheckStatus === 'resolving' || vatInput?.current?.value?.length < 4) && country !== "SE" */}

              {country === "SE"
                ? <button disabled={((!companyCheckStatus || companyCheckStatus === 'resolving') && orgInput?.current?.value?.length > 0) ? true : false} type="submit" className="arrow">Gå vidare</button>
                // : <>{vatInput?.current?.value?.length < 4 ? <button disabled type="submit" className="arrow">Gå vidare</button> : <button type="submit" className="arrow">Gå vidare</button>}</>
                : <button disabled={(!companyCheckStatus || companyCheckStatus === 'resolving' || vatInput?.current?.value?.length < 4) ? true : false} type="submit" className="arrow">Gå vidare</button>
              }

              {/* // ? <button disabled type="submit" className="arrow">Gå vidare</button> */}
              {/* // : <button type="submit" className="arrow">Gå vidare</button> */}

            </>
            : <button onClick={() => setStep(1)}>Försök igen</button>
          }
        </form>
      }
      {detailsStep === 2 &&
        <form onSubmit={submitDetails}>
          <h2>Företagets adress</h2>
          <div className="form-row">
            <label htmlFor="address">Postadress</label>
            <input onBlur={(e) => handleInputValidity(e)} onChange={(e) => { setCompanyDetails(details => ({ ...details, address: e.target.value })); handleInputValidity(e) }} type="text" name="address" defaultValue={companyDetails.address} required minLength={2} />
            <img className="valid" src={Valid} alt="check" />
          </div>
          <div className="form-row">
            <label htmlFor="postcode">Postnummer</label>
            <input onBlur={(e) => handleInputValidity(e)} onChange={(e) => { setCompanyDetails(details => ({ ...details, postcode: e.target.value })); handleInputValidity(e) }} type="tel" name="postcode" defaultValue={companyDetails.postcode} required minLength={2} />
            <img className="valid" src={Valid} alt="check" />
          </div>
          <div className="form-row">
            <label htmlFor="city">Stad</label>
            <input onBlur={(e) => handleInputValidity(e)} onChange={(e) => { setCompanyDetails(details => ({ ...details, city: e.target.value })); handleInputValidity(e) }} type="text" name="city" defaultValue={companyDetails.city} required minLength={2} />
            <img className="valid" src={Valid} alt="check" />
          </div>
          {cartStatus.status !== 'cart-error'
            ? <button type="submit" className="arrow">Gå vidare</button>
            : <button onClick={() => setStep(1)}>Försök igen</button>
          }
        </form>
      }
    </div>
  )
}
