import React, { useState, useEffect } from 'react'
import ReactSlider from 'react-slider'
import { useMutation, gql, useQuery } from '@apollo/client'
import { useAppState } from '../context.jsx'

const PRODUCT = gql`
  query subscriptionProductPrices($country: String){
    subscriptionProductPrices(country: $country) {
      subscriptionProductPrices {
        price
        priceWithVat
        seats
      }
    }
  }
`

export default function License({ license, setLicense, setStep, country, customer }) {

  const [licenseData, setLicenseData] = useState()
  const currentLicense = licenseData?.find(item => { return item.seats === license.quantity })
  const { setCart, cartStatus, setCartStatus } = useAppState()
  const [emptyCart] = useMutation(EMPTY_CART)

  const { data: productData } = useQuery(PRODUCT, {
    variables: {
      country: country
    }
  })

  useEffect(() => {
    setLicenseData(productData?.subscriptionProductPrices?.subscriptionProductPrices)
  }, [productData])

  useEffect(() => {
    setLicense(license => ({ ...license, price: currentLicense?.price }))
  }, [currentLicense])

  useEffect(() => {
    cartStatus.status === 'cart-error' && setCartStatus(state => ({ ...state, status: '', message: '' }))
    emptyCart({ variables: { input: { clientMutationId: "1234" } } }).then(res => console.log(res)).catch(() => { })
  }, [])

  useEffect(() => {
    const session = localStorage.getItem('woo-session')
    if (session) {
      localStorage.removeItem('woo-session')
    }
  }, [localStorage.getItem('woo-session')])

  const [addToCart] = useMutation(ADD_TO_CART, {
    onCompleted: () => {
      console.log('Produkt tillagd');
      setCart(currentLicense)
      setCartStatus(state => ({ ...state, status: 'resolving' }))
    },
    onError: (err) => {
      console.log(err.message);
      console.log('Kunde inte lägga till produkt');
      setCartStatus(state => ({ ...state, status: 'cart-error', message: err.message }))
    },
  })
  const [addSubscriptionSwitch] = useMutation(ADD_SUBSCRIPTION_SWITCH, {
    onCompleted: (res) => {
      console.log(res);
      setCart(currentLicense)
      setCartStatus(state => ({ ...state, status: 'resolving' }))
    },
    onError: (err) => {
      console.log(err)
      setCartStatus(state => ({ ...state, status: 'cart-error', message: err.message }))
    }
  })

  const handleAddToCart = (e) => {
    e.preventDefault();
    const session = localStorage.getItem('woo-session')
    if (session) {
      localStorage.removeItem('woo-session')
    }
    if (customer?.subscription.subscriptionType === "TRIAL") {
      addSubscriptionSwitch({
        variables: { input: { clientMutationId: "123", productVariation: 1907 + license.quantity } }
      })
    }
    else {
      addToCart({
        variables: { input: { productId: 1907, quantity: 1, variationId: 1907 + license.quantity, clientMutationId: "123" } }
      })
    }
    setStep(2)
  }

  return (
    <div className="license">
      <form>
        <h3>Välj privat/företag</h3>
        <div className="options">
          <button onClick={(e) => { setLicense(license => ({ ...license, type: "private" })); e.preventDefault(); }} className={`private ${license.type === 'private' && 'checked'}`}>
            <h3>Privat</h3>
            <span>Priset visas inkl. moms</span>
          </button>
          <button disabled={currentLicense?.priceWithVat === null} onClick={(e) => { setLicense(license => ({ ...license, type: "company" })); e.preventDefault() }} className={`company ${license.type === 'company' && 'checked'}`}>
            <h3>Företag</h3>
            <span>Priset visas exkl. moms</span>
          </button>
        </div>

        <div className="licenses">
          <h3>Antal licenser</h3>
          <div className="range-wrap d-flex align-items-center">
            <span>1</span>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              min={1}
              max={20}
              renderThumb={(props, state) => <div {...props}>{state.value}</div>}
              onChange={(props) => { setLicense(license => ({ ...license, quantity: props })); }}
              defaultValue={license.quantity}
            />
            <span>20</span>
          </div>
        </div>
        
        <div className="price">
          <h3>{license.type === "" || currentLicense?.priceWithVat === undefined 
            ? <span className="empty"></span>
            : license.type === "private" ? `${Math.round(parseInt(currentLicense?.priceWithVat))}kr inkl. moms` : license.type === "company" ? `${currentLicense?.price}kr exkl. moms` : ''}
          </h3>
          <div><span>Priset är per år.</span></div>
          { customer?.subscription.subscriptionType === "TRIAL" || customer?.subscription.subscriptionType === "NONE" &&
            <div><span>Pausit är gratis de 21 första dagarna.</span></div>
          }
        </div>

        {license.type !== "" && currentLicense?.priceWithVat !== undefined
          ? <button className="arrow" onClick={(e) => handleAddToCart(e)}>Gå vidare</button>
          : <button disabled className="disabled arrow">Gå vidare</button>
        }
      </form>
    </div>
  )
}

const ADD_TO_CART = gql`
  mutation($input: AddToCartInput!) {
    addToCart(input: $input) {
      clientMutationId
    }
  }
`
const ADD_SUBSCRIPTION_SWITCH = gql`
  mutation($input: AddSubscriptionSwitchToCartInput!) {
    addSubscriptionSwitchToCart(input: $input) {
      clientMutationId
      cartTotalPay
      yearlyCost
    }
  }
`
const EMPTY_CART = gql`
  mutation($input: EmptyCartInput!) {
    emptyCart(input: $input) {
      clientMutationId
    }
  }
`
