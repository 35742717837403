import React, { useState, useEffect } from 'react'
import ReactSlider from 'react-slider'
import { useMutation, gql, useQuery } from '@apollo/client'
import { useAppState } from '../context.jsx'
import ClipLoader from 'react-spinners/ClipLoader'

export default function OrderItem({ license, setLicense, setStep, country }) {

  const [orderDataStatus, setOrderDataStatus] = useState('retrieving')
  const [licenseData, setLicenseData] = useState()
  const [productDataStatus, setProductDataStatus] = useState(false)

  const { data: productData } = useQuery(PRODUCT, {
    variables: {
      country: country
    }
  })

  const [replaceOrderItem] = useMutation(REPLACE_ORDER_ITEM)


  const handleReplaceOrderItem = quantity => {
    setOrderDataStatus("updating")
    const session = localStorage.getItem("woo-session")
    if (session) {
      localStorage.removeItem("woo-session")
    }
    replaceOrderItem({
      variables: { input: { productVariation: 1907 + quantity } },
    })
      .then(res => {
        setLicense(license => ({
          ...license,
          quantity: res.data.replaceOrderItem.licenseCount,
          price: res.data.replaceOrderItem.subtotalRecurring,
          priceWithVat: res.data.replaceOrderItem.totalRecurring,
        }))

        setOrderDataStatus("retrieved")
      })
      .catch(err => {
        console.log(err)
        setOrderDataStatus("failed")
      })
  }

  useEffect(() => {
    setLicenseData(
      productData?.subscriptionProductPrices?.subscriptionProductPrices
    )
    setProductDataStatus("retrieved")
  }, [productData])

  useEffect(() => {
    handleReplaceOrderItem(1)
  }, [])
  


  useEffect(() => {
    const session = localStorage.getItem("woo-session")
    if (session) {
      localStorage.removeItem("woo-session")
    }
  }, [localStorage.getItem("woo-session")])

  return (
    <div className="license">
      <form>
        <h3>Välj privat/företag</h3>
        <div className="options">
          <button
            onClick={e => {
              setLicense(license => ({ ...license, type: "private" }))
              e.preventDefault()
            }}
            className={`private ${license.type === "private" && "checked"}`}
          >
            <h3>Privat</h3>
            <span>Priset visas inkl. moms</span>
          </button>
          <button
            disabled={license?.priceWithVat === null}
            onClick={e => {
              setLicense(license => ({ ...license, type: "company" }))
              e.preventDefault()
            }}
            className={`company ${license.type === "company" && "checked"}`}
          >
            <h3>Företag</h3>
            <span>Priset visas exkl. moms</span>
          </button>
        </div>
        {license.type !== "" ? (
          "retrieving" !== orderDataStatus && productDataStatus ? (
            <>
              <div className="licenses">
                <h3>Antal licenser</h3>
                <div className="range-wrap d-flex align-items-center">
                  <span>1</span>
                  <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    min={1}
                    max={20}
                    disabled={
                      "retrieving" === orderDataStatus ||
                      "updating" === orderDataStatus
                    }
                    renderThumb={(props, state) => (
                      <div {...props}>{state.value}</div>
                    )}
                    onChange={props => {
                      setLicense(license => ({
                        ...license,
                        quantity: props,
                        price: licenseData[props - 1].price,
                        priceWithVat: licenseData[props - 1].priceWithVat,
                      }))
                    }}
                    onAfterChange={props => {
                      handleReplaceOrderItem(props)
                    }}
                    defaultValue={1}
                  />
                  <span>20</span>
                </div>
              </div>

              <div className="price">
                <h3>
                  {license.type === "" ||
                  license?.priceWithVat === undefined ||
                  "retrieving" === orderDataStatus ? (
                    <span className="empty"></span>
                  ) : license.type === "private" ? (
                    `${Math.round(
                      parseInt(license?.priceWithVat)
                    )}kr inkl. moms`
                  ) : license.type === "company" ? (
                    `${license?.price}kr exkl. moms`
                  ) : (
                    ""
                  )}
                </h3>
                <div>
                  <span>Priset är per år.</span>
                </div>
              </div>
              {"retrieved" === orderDataStatus ? (
                <button
                  disabled={license.type === ""}
                  className="arrow"
                  onClick={e => setStep(2)}
                >
                  Köp {license?.quantity > 1 ? "licenser" : "licens"}
                </button>
              ) : (
                <span className="spinner">
                  <ClipLoader size={16} color={"#ffffffbb"} />
                </span>
              )}
            </>
          ) : (
            <span className="spinner">
              <ClipLoader size={16} color={"#ffffffbb"} />
            </span>
          )
        ) : (
          ""
        )}
      </form>
    </div>
  )
}

const PRODUCT = gql`
  query subscriptionProductPrices($country: String){
    subscriptionProductPrices(country: $country) {
      subscriptionProductPrices {
        price
        priceWithVat
        seats
      }
    }
  }
`

const ORDER_PRICES = gql`
  query {orderPrices {
    totalNow
    subtotalNow
    vatNow
    totalRecurring
    subtotalRecurring
    vatRecurring
    licenseCount
  }}
`


const REPLACE_ORDER_ITEM = gql`
  mutation ReplaceOrderItem($input: ReplaceOrderItemInput!) {
    replaceOrderItem(input: $input) {
      totalNow
      subtotalNow
      vatNow
      totalRecurring
      subtotalRecurring
      vatRecurring
      licenseCount
    }
  }
`
