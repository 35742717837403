import React, { useState } from 'react'

export default function OtherPayment() {

  const [isContact] = useState(false)

  return (
    <div className="other-payment">
      {!isContact ? 
        <>
          <h2>Jag har inte möjlighet att betala med kort</h2>
          <p>Vid eventuellt köp av tjänsten efter testperiod görs betalning med kreditkort. Är betalning med kort inte möjligt ska ni inte använda denna webbshop utan får då istället kontakta oss via sales@pausit.se så löser vi allt detta manuellt. </p>
          <a className="custom" href="mailto:sales@pausit.se"><button className="arrow">Kontakta oss</button></a>
        </>
        : <form>
            <h2>Kontakt</h2>
            <div className="form-row">
              <label htmlFor="field1">Fält</label>
              <input type="text" name="field1"/>
            </div>
            <div className="form-row">
              <label htmlFor="field2">Fält</label>
              <input type="text" name="field2"/>
            </div>
            <div className="form-row">
              <label htmlFor="field3">Fält</label>
              <input type="text" name="field3"/>
            </div>
            <div className="form-row">
              <label htmlFor="field4">Fält</label>
              <input type="text" name="field4"/>
            </div>
            <div className="form-row">
              <label htmlFor="field5">Fält</label>
              <input type="text" name="field5"/>
            </div>
            <button type="submit" className="arrow">Skicka in formulär</button>
        </form>
      }

    </div>
  )
}
