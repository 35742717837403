import React, { useState, useEffect } from 'react'
import License from '../../components/checkout/License'
import OrderItem from '../../components/checkout/OrderItem'
import PrivateDetails from '../../components/checkout/PrivateDetails'
import CompanyDetails from '../../components/checkout/CompanyDetails'
import CheckoutForm from './CheckoutForm'
import PayTrialOnHoldOrderForm from "./PayTrialOnHoldOrderForm"
import OtherPayment from '../../components/checkout/OtherPayment'

import Order from './Order'
import ChooseCountry from './ChooseCountry'
import { useAppState } from '../context'

export default function CheckoutSteps({ step, setStep, isCreditCard, setIsCreditCard, detailsStep, setDetailsStep, authError, setAuthError }) {
  const { customerData } = useAppState();
  const [country, setCountry] = useState("")

  useEffect(() => {
    step === 0 && setLicense(license => ({ ...license, type: "" }))
  }, [step])

  useEffect(() => {
    country !== "" && setStep(1)
    if (country !== "") {
      setPrivateDetails(details => ({ ...details, country: country }))
      setCompanyDetails(details => ({ ...details, country: country }))
    }
  }, [country])

  // License
  const [license, setLicense] = useState({
    type: "",
    quantity: 1,
    price: 100,
    priceWithVat: 125,
  })

  // Private
  const [privateDetails, setPrivateDetails] = useState({
    firstname: "",
    lastname: "",
    address: "",
    postcode: "",
    city: "",
    // country: ""
    country: country
  })

  // Company
  const [companyDetails, setCompanyDetails] = useState({
    company: "",
    contactperson: "",
    contactFirstname: "",
    contactLastname: "",
    // country: "",
    country: country,
    vat: "",
    org: "",
    address: "",
    postcode: "",
    city: ""
  })

  return (
    <div className="order-steps">
      {step === 0 && (
        <ChooseCountry
          country={country}
          setCountry={value => setCountry(value)}
        />
      )}
      {step === 1 && (
        <>
          {"TRIAL_ON_HOLD" === customerData?.subscription?.subscriptionType ? (
            <OrderItem
              license={license}
              setLicense={value => {
                setLicense(value)
              }}
              setStep={value => {
                setStep(value)
              }}
              country={country}
            />
          ) : (
            <License
              license={license}
              setLicense={value => {
                setLicense(value)
              }}
              setStep={value => {
                setStep(value)
              }}
              country={country}
              customer={customerData}
            />
          )}
        </>
      )}
      {step === 2 && (
        <>
          {license.type === "private" ? (
            <PrivateDetails
              privateDetails={privateDetails}
              setPrivateDetails={value => setPrivateDetails(value)}
              detailsStep={detailsStep}
              setDetailsStep={value => setDetailsStep(value)}
              setStep={value => setStep(value)}
            />
          ) : (
            <CompanyDetails
              companyDetails={companyDetails}
              setCompanyDetails={value => setCompanyDetails(value)}
              detailsStep={detailsStep}
              setDetailsStep={value => setDetailsStep(value)}
              setStep={value => setStep(value)}
              country={country}
            />
          )}
        </>
      )}
      {step === 3 && (
        <Order
          license={license}
          setLicense={value => setLicense(value)}
          privateDetails={privateDetails}
          setPrivateDetails={value => setPrivateDetails(value)}
          companyDetails={companyDetails}
          setCompanyDetails={value => setCompanyDetails(value)}
          setStep={value => setStep(value)}
          setDetailsStep={value => setDetailsStep(value)}
          customer={customerData}
        />
      )}
      {step === 4 && (
        <>
          {isCreditCard ? (
            "TRIAL_ON_HOLD" === customerData?.subscription?.subscriptionType ? (
              <PayTrialOnHoldOrderForm
                authError={authError}
                license={license}
                privateDetails={privateDetails}
                companyDetails={companyDetails}
                customer={customerData}
                setAuthError={value => setAuthError(value)}
                setIsCreditCard={value => setIsCreditCard(value)}
              />
            ) : (
              <CheckoutForm
                authError={authError}
                license={license}
                privateDetails={privateDetails}
                companyDetails={companyDetails}
                customer={customerData}
                setAuthError={value => setAuthError(value)}
                setIsCreditCard={value => setIsCreditCard(value)}
              />
            )
          ) : (
            <OtherPayment />
          )}
        </>
      )}
    </div>
  )
}
