import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { useAppState } from '../context'
import { gql, useMutation } from '@apollo/client'
import OrderReceived from './OrderReceived'
import ClipLoader from 'react-spinners/ClipLoader'
import Arrow from '../../images/icons/arrow-dark.svg'
import CookieBar from '../CookieBar'
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics"

export default function CheckoutStart() {

  const { customerData, setCartStatus } = useAppState();
  const [trialCompleted, setTrialCompleted] = useState(false)
  const [trialStatus, setTrialStatus] = useState('')
  const [emptyCart] = useMutation(EMPTY_CART)
  const { getCid, trackingId } = useGoogleAnalytics()

  useEffect(() => {
    emptyCart({ variables: { input: { clientMutationId: "1234" } } }).catch(() => {})
  }, [])

  // useEffect(() => {
  //   if (
  //     customerData &&
  //     customerData?.subscription.subscriptionType !== "NONE"
  //   ) {
  //     console.log('Redirect to checkout')
  //     navigate("/shop/checkout")
  //   }
  // }, [customerData])


  const [kbCheckout] = useMutation(CHECKOUT, {
    onCompleted({ kbCheckout }) {
      console.log(kbCheckout);
      localStorage.removeItem('woo-session')
      setTrialStatus('resolved')
      setTrialCompleted(true)
    },
    onError(error) {
      console.log(error);
      setTrialStatus('error')
    }
  })

  const [addToCart] = useMutation(ADD_TO_CART, {
    onCompleted: async () => {
      console.log('Product was added to cart');
      setCartStatus(state => ({ ...state, status: 'resolving' }))
      const clientId = await getCid()
      customerData &&
        (await kbCheckout({
          variables: {
            input: {
              clientMutationId: "12345",
              startTrial: true,
              billing: {
                email: customerData?.email,
              },
              gaCID: clientId,
              gaTID: trackingId,
            },
          },
        }))
    },
    onError: (err) => {
      console.log(err.message);
      setTrialStatus('')
      console.log('There was an error adding your product');
      setCartStatus(state => ({ ...state, status: 'cart-error', message: err.message }))
    },
  })


  return (
    <div>
      {!trialCompleted ?
        <>
          <h2>Starta en gratis provperiod eller köp?</h2>
          <p>Vid köp debiteras du först efter 21 dagar.</p>

          <div className="btn-group">
            <div className="option">
              <button disabled={trialStatus === 'resolving' || customerData?.subscription.subscriptionType !== "NONE"} onClick={() => {
                setTrialStatus('resolving')
                addToCart({
                  variables: { input: { productId: 1907, quantity: 1, variationId: 1927, clientMutationId: "123" } }
                })
              }}>
                Prova
                <img src={Arrow} alt="" />
              </button>
              <div><small>Prova gratis i 21 dagar.</small></div>
              <div><small>Inga kortuppgifter krävs.</small></div>
            </div>
            <div className="option">
              <button disabled={trialStatus === 'resolving'} onClick={() => {
                  navigate("/shop/checkout")
              }}
              >
                Köp
                <img src={Arrow} alt="" />
              </button>
              <div><small>Gratis första 21 dagarna.</small></div>
              <div><small>Därefter från 55kr/månad.</small></div>
            </div>
          </div>
          <div>
            {/* <Link to={customerData?.subscription?.subscriptionType !== "NONE" ? "/shop" : "/contact"}>Är ni fler än 20? Kontakta Pausit</Link> */}
            <Link to="/contact">Är ni fler än 20? Kontakta Pausit</Link>
          </div>
          <div className="messages">
            {trialStatus === 'resolving' &&
              <div className="text-center spinner">
                <p className="mb-1">Startar provperiod</p>
                <ClipLoader size={22} color={"#ffffffbb"} />
              </div>
            }
          </div>
        </>
        : <OrderReceived trial />
      }
      {/* <CookieBar /> */}
    </div>
  )
}

const CHECKOUT = gql`
  mutation KbCheckout($input: KbCheckoutInput!) {
    kbCheckout(input: $input) {
      order {
        databaseId
        orderNumber
        total
        lineItems {
          nodes {
            product {
              node {
                name
                databaseId
              }
            }
          }
        }
      }
    }
  }
`
const ADD_TO_CART = gql`
  mutation($input: AddToCartInput!) {
    addToCart(input: $input) {
      clientMutationId
    }
  }
`

const EMPTY_CART = gql`
  mutation($input: EmptyCartInput!) {
    emptyCart(input: $input) {
      clientMutationId
    }
  }
`
