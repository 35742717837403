import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select';
import { gql, useMutation, useQuery } from '@apollo/client'
import { useAppState } from '../context';
import ClipLoader from 'react-spinners/ClipLoader'

export default function ChooseCountry({ setCountry }) {

  const [selectedOption] = useState(null);
  const { customerData } = useAppState()
  const [countries, setCountries] = useState(null)
  const { data: wcCountries } = useQuery(COUNTRIES)
  const selectRef = useRef();
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted(res) {
    },
    onError(error) {
      console.log(error.message);
    }
  })

  useEffect(() => {
    setCountry("")
  }, [])

  useEffect(() => {
    setCountries()
    let c = []
    wcCountries?.countries.forEach(country => {
      c.push({
        value: country.code,
        label: country.name
      })
    });
    setCountries(c)
  }, [wcCountries])

  useEffect(() => {
    if (countries?.length > 0) {
      const sweden = countries?.find(country => country.value === "SE")
      const index = countries?.indexOf(countries?.find(country => country.value === "SE"));
      countries?.splice(index, 1)
      countries?.splice(0, 0, sweden)
    }
  }, [countries])

  return (
    <div className="choose-country">
      <h2>Välj land</h2>
      {countries?.length ?
        <div className={`countries`}>
          <Select
            ref={selectRef}
            className={"react-country-select"}
            classNamePrefix="react-select"
            defaultValue={selectedOption}
            onChange={(option) => {
              setCountry(option.value); updateCustomer({
                variables: { input: { id: customerData?.id, billing: { country: option.value } } }
              })
            }}
            options={countries}
            placeholder={"Sök eller välj i listan"}
            noOptionsMessage={(e) => "Hittade inga länder"}
            isDisabled={! countries?.length }
          />
        </div>
        : <span className="spinner"><ClipLoader size={16} color={"#ffffffbb"} /></span>
      }
    </div>

  )
}

const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
          billing {
          country
        }
      }
    }
  }
`;

const COUNTRIES = gql`
  query {countries {
    code
    name
  }}
`
