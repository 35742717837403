import React from 'react'
import { useAppState } from '../context';
import Valid from '../../images/check.svg'

export default function Details({ privateDetails, setPrivateDetails, detailsStep, setDetailsStep, setStep }) {

  const { cartStatus } = useAppState();

  const submitDetails = (e) => {
    e.preventDefault()
    setStep(3)
  }

  const handleInputValidity = (e) => {
    e.preventDefault();
    switch (e.target.validity.valid) {
      case true:
        e.target.nextSibling?.classList.add('d-block')
        break;
      case false:
        e.target.nextSibling?.classList.remove('d-block')
      default:
        break;
    }
  }

  return (
    <div className="details private-details">

      {detailsStep === 1 &&
        <form onSubmit={() => setDetailsStep(2)}>
          <h2>Fyll i ditt namn</h2>
          <div className="form-row">
            <label htmlFor="firstname">Ditt förnamn</label>
            <input onKeyUp={(e) => handleInputValidity(e)} onChange={(e) => setPrivateDetails(details => ({ ...details, firstname: e.target.value }))} type="name" name="firstname" defaultValue={privateDetails.firstname}
              required minLength={2} />
            <img className="valid" src={Valid} alt="check" />
          </div>
          <div className="form-row">
            <label htmlFor="lastname">Ditt efternamn</label>
            <input onKeyUp={(e) => handleInputValidity(e)} onChange={(e) => setPrivateDetails(details => ({ ...details, lastname: e.target.value }))} type="text" name="lastname" defaultValue={privateDetails.lastname}
              required minLength={2} />
            <img className="valid" src={Valid} alt="check" />
          </div>
          {cartStatus.status !== 'cart-error'
            ? <button type="submit" className="arrow">Gå vidare</button>
            : <button onClick={() => setStep(1)}>Försök igen</button>
          }
        </form>
      }
      {detailsStep === 2 &&
        <form onSubmit={submitDetails}>
          <h2>Fyll i adress</h2>
          <div className="form-row">
            <label htmlFor="address">Postadress</label>
            <input onKeyUp={(e) => handleInputValidity(e)} required minLength={2} onChange={(e) => setPrivateDetails(details => ({ ...details, address: e.target.value }))} type="text" name="address" defaultValue={privateDetails.address} />
            <img className="valid" src={Valid} alt="check" />
          </div>
          <div className="form-row">
            <label htmlFor="postcode">Postnummer</label>
            <input onKeyUp={(e) => handleInputValidity(e)} required minLength={2} onChange={(e) => setPrivateDetails(details => ({ ...details, postcode: e.target.value }))} type="tel" name="postcode" defaultValue={privateDetails.postcode} />
            <img className="valid" src={Valid} alt="check" />
          </div>
          <div className="form-row">
            <label htmlFor="city">Stad</label>
            <input onKeyUp={(e) => handleInputValidity(e)} required minLength={2} onChange={(e) => setPrivateDetails(details => ({ ...details, city: e.target.value }))} type="text" name="city" defaultValue={privateDetails.city} />
            <img className="valid" src={Valid} alt="check" />
          </div>
          {cartStatus.status !== 'cart-error'
            ? <button type="submit" className="arrow">Gå vidare</button>
            : <button onClick={() => setStep(1)}>Försök igen</button>
          }
        </form>
      }
    </div>
  )
}

