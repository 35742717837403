import React, { useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics"
import { useStripeSource } from "../../hooks/useStripeSource"
import ClipLoader from 'react-spinners/ClipLoader'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe } from '@stripe/react-stripe-js'
import Creditcard from '../../images/creditcard.svg'
import { navigate } from 'gatsby'
import { gql, useMutation } from '@apollo/client'

export default function PayTrialOnHoldOrderForm({ license, authError, privateDetails, companyDetails, customerData, setAuthError, setIsCreditCard }) {

  const stripe = useStripe()
  const { handleStripe } = useStripeSource()
  const { getCid, trackingId } = useGoogleAnalytics()
  const { viewer } = useAuth();

  const [message, setMessage] = useState('')
  const [order, setOrder] = useState()
  const [source, setSource] = useState()
  const [payOrderStatus, setPayOrderStatus] = useState("")
  const [paymentIntent, setPaymentIntent] = useState("")
  const [paymentDetails, setPaymentDetails] = useState({
    name: ""
  })

  const [payOrder] = useMutation(PAY_ORDER)

  const payPendingOrder = async () => {
    const source = await handleStripe(
      paymentDetails,
      CardNumberElement,
      setPayOrderStatus
    )
    setSource(source.id)
    const clientId = await getCid()
    let billing = {};

    switch (license.type) {
      case "private":
        billing = {
          firstName: privateDetails.firstname,
          lastName: privateDetails.lastname,
          address1: privateDetails.address,
          city: privateDetails.city,
          postcode: privateDetails.postcode,
          email: viewer?.email
        };
        break;
        case "company":
          billing = {
            company: companyDetails.company,
            firstName: companyDetails.contactFirstname,
            lastName: companyDetails.contactLastname,
            address1: companyDetails.address,
            postcode: companyDetails.postcode,
            city: companyDetails.city,
            email: viewer.email
        };
        break;
      default:
        break;
    }

    setPayOrderStatus("resolving")
    payOrder({
      variables: {
        input: {
          billing: billing,
          stripeSource: source.id,
          gaCID: clientId,
          gaTID: trackingId,
        },
      },
    })
      .then(res => {
        console.log(res.data.payOrderOnHold)
        setOrder(res.data.payOrderOnHold?.order)
        if (res.data.payOrderOnHold?.paymentIntentSecret !== "") {
          setPaymentIntent(res.data.payOrderOnHold.paymentIntentSecret)
          stripe
            .confirmCardPayment(res.data.payOrderOnHold.paymentIntentSecret)
            .then(res => {
              console.log(res)
              if (res?.paymentIntent?.status === "succeeded") {
                navigate("/shop/checkout/order-received", { state: order })
              } else {
                setAuthError(true)
                setPayOrderStatus("")
              }
            })
            .catch(err => {
              console.log(err)
              setPayOrderStatus(err.message)
            })
        } else {
          navigate("/shop/checkout/order-received", { state: order })
        }
      })
      .catch(err => {
        console.log(err)
        setPayOrderStatus(err.message)
      })
  }


  return (
    <div className="payment">
      <form onSubmit={(e) => {
        e.preventDefault();
        if (!authError) {
          payPendingOrder(e)
        } else {
          setPayOrderStatus('resolving')
          setMessage('Autentisering pågår')
          // Försök igen - Autentisering
          stripe.confirmCardPayment(paymentIntent, {
            payment_method: source
          }).then((result) => {
            if (result.error) {
                console.log(result.error);
                setMessage('Autentisering misslyckades.')
                setAuthError(true);
                setPayOrderStatus('')
                setMessage('')
            } else {
                setMessage('Autentisering genomförd. Din order slutförs...')
                setAuthError(false)
                setPayOrderStatus('')
                setPaymentIntent('')
                localStorage.removeItem('woo-session')
                navigate('/shop/checkout/order-received', { state: order })
            }
          }
          )
        }
      }}>

      {!authError &&
        <>
          <div>
            <img src={Creditcard} alt="creditcard" />
            <h2>Betalning</h2>
          </div>
          <div className="form-row">
            <label htmlFor="name">Namn på kort</label>
            <input required minLength={2} onChange={(e) => setPaymentDetails(details => ({ ...details, name: e.target.value }))} type="text" name="name" defaultValue={paymentDetails.name} placeholder="Namn" />
          </div>
          <div className="form-row">
            <label htmlFor="">Kortnummer</label>
            <CardNumberElement />
          </div>
          <div className="form-row d-flex date">
            <div>
              <label>Datum</label>
              <CardExpiryElement />
            </div>
            <div>
              <label>CVC</label>
              <CardCvcElement />
            </div>
          </div>
        </>
      }
      <div className="messages auth-error">
        {authError && <div className="checkout-error">
          <h2>Autentisering misslyckades</h2>
          <div>
            <strong>Försök igen eller avbryt ditt köp.</strong>
          </div>
        </div>
        }
      </div>

      <div className="d-flex" style={{ justifyContent: 'center' }}>
        <button type="submit" disabled={payOrderStatus === 'resolving'}>
          {!authError ? 'Genomför köp' : 'Försök igen'}
        </button>
      </div>
      <div className={`messages ${authError && 'auth-error'}`}>
        {payOrderStatus === 'resolving' &&
          <div className="spinner">
              <div><small>{message}</small></div>
              <ClipLoader size={22} color={"#ffffffbb"} />
          </div>
        }
        {authError && <div className="checkout-error">Köpet kunde inte genomföras. Försök igen eller prova ett annat kort.</div>}

      </div>
      {!authError
        ? 
          <span onClick={() => setIsCreditCard(false)}>Jag har inte möjlighet att betala med kort</span>
        : 
          <span onClick={(e) => {
            e.preventDefault();
            if (customerData?.subscription.subscriptionType === "NONE") {
              navigate('/shop/checkout/order-received', { state: order })
            }
            else {
              window.location.replace("/user")
            }
          }}>
        {customerData?.subscription.subscriptionType === "NONE" ? 'Jag vill gå vidare ›' : 'Avbryt köp ›'}</span>}
      </form>
    </div>
  )
}


const PAY_ORDER = gql`
  mutation PayOrderOnHold($input: PayOrderOnHoldInput!) {
    payOrderOnHold(input: $input) {
      mutationStatus
      paymentIntentSecret
      order {
        databaseId
        orderNumber
        total
        lineItems {
          nodes {
            product {
              node {
                name
                databaseId
              }
            }
          }
        }
      }
    }
  }
`