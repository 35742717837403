import React from 'react'
import Layout from '../../components/Layout'
import { Router } from "@reach/router"
import PrivateRoute from '../../components/privateRoute'
import CheckoutStart from '../../components/checkout/CheckoutStart'
import CheckoutPage from '../../components/checkout/CheckoutPage'
import OrderReceived from '../../components/checkout/OrderReceived'
import { useAppState } from '../../components/context'
import { navigate } from 'gatsby-link'


export default function Shop() {
  const isBrowser = () => typeof window !== "undefined"
  const { customerData } = useAppState();

  return (
    <div className="shop">
      <Layout>
        <Router>
          <PrivateRoute path="/shop/checkout/order-received" component={OrderReceived} />
          {customerData && ( customerData?.subscription?.subscriptionType === "NONE" || customerData?.subscription?.subscriptionType === "TRIAL"  || customerData?.subscription?.subscriptionType === "TRIAL_ON_HOLD" || customerData?.subscription?.subscriptionType === "EXPIRED" )
          ?
            <>
              <PrivateRoute path="/shop" component={CheckoutStart} />
              <PrivateRoute path="/shop/checkout" component={CheckoutPage} />
            </>
            : isBrowser() && customerData && window.location.pathname !== "/shop/checkout/order-received" && navigate("/user")
          }
        </Router>
        
      </Layout>
    </div>
  )
}
